import { Link, NavLink } from "react-router-dom";
import styles from "./navBar.module.css";
import logo from "../../assets/logo_completo.png";
import { BsLinkedin } from "react-icons/bs";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { useState, useEffect } from "react";

import logo1 from "../../assets/PIER-ROLL-COLOR.svg";
import logo2 from "../../assets/PIER-COLOR.svg";
import logo3 from "../../assets/DOLCHESTER-COLOR.svg";
import logo4 from "../../assets/Corona/corona-dorado.svg";
import logo5 from "../../assets/LIVERPOOL-GRIS.svg";

export default function Navbar() {
  const [isMobile, setIsMobile] = useState(false);
  const [openDropDown, setOpenDropDown] = useState(false);
  const [menuAbierto, setMenuAbierto] = useState(false);
  const { pathname } = useLocation();
  const user = useSelector((state) => state.usuario);
  const productRoutes = ["/Pier", "/J&G", "/Liverpool", "/Dolchester", "/Pier&Roll"];

  const toggleMenu = () => {
    setMenuAbierto(!menuAbierto);
  };

  const handleResize = () => {
    setIsMobile(window.innerWidth < 1200);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.Navbar}>
        <div className={styles.NavIzq}>
          {/* HAMBURGER MENU */}
          <div className={styles.hamburguesa} onClick={toggleMenu}>
            <div className={`${styles.linea} ${menuAbierto && styles.linea1}`} />
            <div className={`${styles.linea} ${menuAbierto && styles.linea2}`} />
            <div className={`${styles.linea} ${menuAbierto && styles.linea3}`} />
          </div>

          {/* LOGO */}
          <div>
            <Link to="/inicio">
              <img
                src={logo}
                alt="logo"
                className={styles.logo}
                onClick={() => setMenuAbierto(false)}
              />
            </Link>
          </div>
        </div>

        {/* LINKS */}
        <ul className={`${styles.nav} ${menuAbierto ? styles.menuAbierto : ""}`}>
          <li onClick={() => setOpenDropDown(false)}>
            <NavLink
              className={pathname.includes("/inicio") && styles.active}
              end
              to="/inicio"
              onClick={toggleMenu}
            >
              Inicio
            </NavLink>
          </li>
          <li
            className={styles.products}
            onClick={() => {
              setOpenDropDown(!openDropDown);
            }}
          >
            <span
              className={`${styles.headerProducts} ${productRoutes.includes(pathname) && styles.active
                }`}
            >
              <span className={`${styles.spanProducto}`}>PRODUCTOS</span>
              <div className={styles.test}>
                <div
                  className={`
                ${styles.leftIcon}
                ${openDropDown && styles.leftIconOpen}
                ${productRoutes.includes(pathname) && styles.arrowActive}`}
                />
                <div
                  className={`
                ${styles.rightIcon}
                ${openDropDown && styles.rightIconOpen}
                ${productRoutes.includes(pathname) && styles.arrowActive}
                `}
                />
              </div>
            </span>

            {/* DROP DE PRODUCTOS */}
            <ul
              className={`${styles.dropdown} ${openDropDown ? styles.open : ""}`}
            >
              <div className={`${openDropDown ? styles.goldenBar2: " display-none"}`}></div>
              <li className={styles.li}>
                <NavLink
                  to="/corona"
                  onClick={toggleMenu}
                >
                  <img
                    src={logo4}
                    loading="lazy"
                    alt="tres logos marcas"
                    className={`${styles.tresLogos} ${styles.coronaLogo} ${pathname.includes("/corona") ? styles.activeLogo : ""
                      }`}
                  />
                </NavLink>
              </li>
              <li className={styles.li}>
                <NavLink
                  // className={pathname.includes("/Pier") && styles.active}
                  to="/Pier"
                  onClick={toggleMenu}
                >
                  <img
                    src={logo2}
                    loading="lazy"
                    alt="tres logos marcas"
                    className={`${styles.tresLogos} ${pathname.includes("/Pier") ? styles.activeLogo : ""
                      }`}
                  />
                </NavLink>
              </li>
              <li className={styles.li}>
                <NavLink
                  to="/Liverpool"
                  onClick={toggleMenu}
                >
                  <img
                    src={logo5}
                    loading="lazy"
                    alt="tres logos marcas"
                    className={`${styles.tresLogos} ${pathname.includes("/Liverpool") ? styles.activeLogo : ""
                      }`}
                  />
                </NavLink>
              </li>
              <li className={styles.li}>
                <NavLink
                  // className={pathname.includes("/Dolchester") && styles.active}
                  to="/Dolchester"
                >
                  <img
                    src={logo3}
                    loading="lazy"
                    alt="tres logos marcas"
                    onClick={toggleMenu}
                    className={`${styles.tresLogos} ${pathname.includes("/Dolchester") ? styles.activeLogo : ""
                      }`}
                  />
                </NavLink>
              </li>
              <li className={styles.li}>
                <NavLink
                  to="/Pier&Roll"
                  onClick={toggleMenu}
                >
                  <img
                    src={logo1}
                    loading="lazy"
                    alt="tres logos marcas"
                    className={`${styles.tresLogos}  ${pathname.includes("/Pier&Roll") ? styles.activeLogo : ""
                      }`}
                  />
                </NavLink>
              </li>
              {/* <li className={styles.li}>
              <NavLink
                className={pathname.includes("/J&G") && styles.active}
                to="/J&G"
                onClick={toggleMenu}
              >
                J&G
              </NavLink>
            </li> */}
            </ul>
          </li>

          <li
            onClick={() => {
              setOpenDropDown(false);
              toggleMenu();
            }}
          >
            <NavLink
              className={pathname.includes("/empresa") && styles.active}
              to="/empresa"
              onClick={toggleMenu}
            >
              Empresa
            </NavLink>
          </li>
          {user && user.isAdmin && (
            <li onClick={() => setOpenDropDown(false)}>
              {/* <NavLink
              className={pathname.includes("/dashboard") && styles.active}
              to="/dashboard"
              onClick={toggleMenu}
            >
              Admin
            </NavLink> */}
            </li>
          )}
          {isMobile && (
            <li onClick={() => setOpenDropDown(false)}>
              <NavLink
                className={pathname.includes("/contacto") && styles.active}
                to="/contacto"
                onClick={toggleMenu}
              >
                Contacto
              </NavLink>
            </li>
          )}
        </ul>

        {/* CONTACTO */}
        <div
          onClick={() => setOpenDropDown(false)}
          className={`${styles.contact}`}
        >
          <Link
            to="/contacto"
            onClick={toggleMenu}
            className={pathname.includes("/contacto") && styles.activeContacto}
          >
            <h4>contacto</h4>
          </Link>
          <a
            href="https://www.linkedin.com/company/bronway-tabaco/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <BsLinkedin className={styles.linkedin} />
          </a>
        </div>
      </div>
      <div className={styles.goldenBar}></div>
    </div>
  );
}
